import React, { useCallback, useMemo } from 'react';
import { Amplify, Auth } from 'aws-amplify';

// Views
import LoginView from './loginView';


function LoginContainer() {
  // const [flow, setFlow] = React.useState<'login' | 'signup' | 'forgotPassword'>('login');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordConfirm, setPasswordConfirm] = React.useState('');
  const [authError, setAuthError] = React.useState<string| null>(null);

  // Email is set to lowercase in pre-signup lambda, but there is no pre-signin lambda
  const formattedEmail = useMemo(() => email.toLowerCase().trim(), [email]);

  const flow = useMemo(() => {
    if (window.location.pathname.match(/signup/)) return 'signup';
    if (window.location.pathname.match(/forgot-password/)) return 'forgotPassword';
    return 'login';
  }, [window.location.pathname]);

  const onLogin = useCallback(async () => {
    try {
      const user = await Auth.signIn(formattedEmail, password);
      console.log('User signed in:', user);
    } catch (error: any) {
      console.error('Error signing in:', error);
      if (error?.message) setAuthError(error?.message);
    }
  }, [formattedEmail, password]);

  const onSignUp = useCallback(async () => {
    if (password !== passwordConfirm) {
      alert('Passwords do not match.');
      return;
    }

    try {
      const { user } = await Auth.signUp({
        username: formattedEmail,
        password,
        attributes: {
          email: formattedEmail,
        },
      });
      console.log('User signed up:', user);
      await Auth.signIn(formattedEmail, password);
      
    } catch (error: any) {
      console.error('Error signing up:', error);
      if (error?.message) setAuthError(error?.message);
    }
  }, [formattedEmail, password, passwordConfirm]);

  const onForgotPassword = useCallback(async () => {
    try {
      await Auth.forgotPassword(email);
      console.log('Code sent');
    } catch (error) {
      console.error('Error sending code:', error);
    }
  }, [email]);

  const onGoogleLogin = useCallback(() => {
    Auth.federatedSignIn({
      provider: 'Google' as any,
    });
  }, []);

  // TODO go through this process: https://learn.microsoft.com/en-us/azure/active-directory/develop/publisher-verification-overview
  // const initiateMicrosoftLogin = useCallback(() => {
  //   Auth.federatedSignIn({
  //     // @ts-ignore
  //     provider: 'Microsoft',
  //   });
  // }, []);

  return <LoginView
    flow={flow}
    email={email}
    password={password}
    passwordConfirm={passwordConfirm}
    setEmail={setEmail}
    setPassword={setPassword}
    setPasswordConfirm={setPasswordConfirm}
    onSignup={onSignUp}
    onLogin={onLogin}
    onGoogleLogin={onGoogleLogin}
    onForgotPassword={onForgotPassword}
    authError={authError}
    setAuthError={setAuthError}
  />;
}

export default LoginContainer;

import React from 'react';

// Views
import UserProfileView from './userProfileView';

function UserProfileContainer() {
  return <UserProfileView />;
}

export default UserProfileContainer;

import React, { forwardRef } from 'react';

import styles from './styles.module.scss';

const InputString = forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
>((
  { className, ...props},
  ref
) => {
  return <input className={`${styles.InputString} ${className}`} ref={ref} {...props} />
});

export default InputString;

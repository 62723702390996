// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_text__nM3Iy {\n  font-family: \"Open Sans\", sans-serif;\n  font-size: 24px;\n  line-height: 32px;\n  font-weight: 600;\n  letter-spacing: 0em;\n  margin: 0px;\n}\n\n@media (min-width: 600px) {\n  .styles_text__nM3Iy {\n    font-size: 30px;\n    line-height: 38px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/text/h1/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,oCAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,WAAA;AADF;;AAIA;EACE;IACE,eAAA;IACA,iBAAA;EADF;AACF","sourcesContent":["@import \"@/styles/designTokens/layout.scss\";\n\n.text {\n  font-family: 'Open Sans', sans-serif;\n  font-size: 24px;\n  line-height: 32px;\n  font-weight: 600;\n  letter-spacing: 0em;\n  margin: 0px;\n}\n\n@media (min-width: $tablet-min)  {\n  .text {\n    font-size: 30px;\n    line-height: 38px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "styles_text__nM3Iy"
};
export default ___CSS_LOADER_EXPORT___;

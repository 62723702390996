import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';

// We store these externally so that they're set globally, and an error encountered in any
// of them will alert the rest of them, regardless of which component the error was encountered in.
const authErrorHandlers: Set<(err: any) => void> = new Set();


// This typing is not exhaustive
export type AuthUser = {
  attributes: {
    sub: string; // This is the cognito unique identifier
    email: string;
    family_name: string;
    given_name: string;
    identities: {
      userId: string;
      providerName: string;
      providerType: string;
      issuer: string | null;
      primary: boolean;
      dateCreated: number;
    }[];
  };
};


export default function useAuth({
  onAuthError,
  onLogin,
}: {
  onAuthError?: (err: any) => void;
  onLogin?: () => void;
} = {}) {
  const [hasDoneInitialCheck, setHasDoneInitialCheck] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isPending, setIsPending] = useState<boolean>(true);
  const [cognitoUser, setCognitoUser] = useState<AuthUser | null>(null);
  const [authError, setAuthError] = useState<any>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [idToken, setIdToken] = useState<string | null>(null);

  const checkAuth = useCallback(async () => {
    setIsPending(true);
    try {
      const [
        user,
        session,
      ] = await Promise.all([
        Auth.currentAuthenticatedUser(),
        Auth.currentSession(),
      ]);

      setHasDoneInitialCheck(true);

      setCognitoUser(user);
      setIsAuthenticated(true);
      setIsPending(false);

      const accessToken = session.getAccessToken().getJwtToken();
      const idToken = session.getIdToken().getJwtToken();

      setAccessToken(accessToken);
      setIdToken(idToken);

      return {
        user,
        accessToken,
        idToken,
      };
    } catch (err) {
      setHasDoneInitialCheck(true);

      setAuthError(err);
      authErrorHandlers.forEach(handler => handler(err));
      setIsAuthenticated(false);
      setIsPending(false);
    }
  }, []);

  const handleAuthEvent = useCallback((data: any) => {
    checkAuth();
    const { payload } = data;
    switch (payload.event) {
      case 'signIn':
        console.log('User signed in', payload.data);
        checkAuth()
        .then(result => result?.accessToken && onLogin?.());
        break;
      case 'signOut':
        console.log('User signed out');
        checkAuth();
        break;
      case 'signIn_failure':
        const error = payload?.data?.error;
        error && setAuthError(error);
        console.log('Sign in failure', payload.data);
        break;
      default:
        break;
    }
  }, [checkAuth, onLogin]);

  useEffect(() => {
    // Subscribe to authentication status change events
    const remove = Hub.listen('auth', handleAuthEvent);
    if (onAuthError) authErrorHandlers.add(onAuthError);

    return () => {
      remove();
      if (onAuthError) authErrorHandlers.delete(onAuthError);
    };
  }, [onAuthError, handleAuthEvent]);

  useEffect(() => {
    checkAuth();
  }, []);

  return {
    isAuthenticated,
    isPending,
    hasDoneInitialCheck,
    cognitoUser,
    authError,
    checkAuth,
    logout: () => Auth.signOut(),
    token: {
      access: accessToken,
      id: idToken,
    },
  };
}

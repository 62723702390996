import React from 'react';
import { Stripe, loadStripe } from '@stripe/stripe-js';

const byCredentials: Record<string, Promise<Stripe | null> | undefined> = {};

function useLoadStripe(apiKey: string): Promise<Stripe | null> {
  if (byCredentials[apiKey]) return byCredentials[apiKey] as Promise<Stripe | null>;

  const loading = loadStripe(apiKey);
  byCredentials[apiKey] = loading;

  return loading;
}

export default useLoadStripe;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_text__QP6V8 {\n  font-family: \"Open Sans\", sans-serif;\n  font-size: 14px;\n  line-height: 14px;\n  font-weight: 600;\n  letter-spacing: 0em;\n}", "",{"version":3,"sources":["webpack://./src/components/text/button/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,oCAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;AADF","sourcesContent":["@import \"@/styles/designTokens/layout.scss\";\n\n.text {\n  font-family: 'Open Sans', sans-serif;\n  font-size: 14px;\n  line-height: 14px;\n  font-weight: 600;\n  letter-spacing: 0em;\n}\n\n@media (min-width: $tablet-min)  {\n  .text {\n \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "styles_text__QP6V8"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { BrowserRouter, Navigate, Route, Router, Routes, useNavigate } from 'react-router-dom';

import Header from './views/header';

import UserProfile from './children/userProfile';

import styles from './styles.module.scss';

function AppFrameView() {
  return <div className={`.c-AppFrame ${ styles.AppFrame }`}>
    <Header />
    <Routes>

      <Route path='/profile/*' element={<UserProfile />} />
      <Route path='/hi' element={<div>HI</div>} />

      <Route path='*' element={<Navigate to='/profile' />} />
    </Routes>
  </div>;
}

export default AppFrameView;

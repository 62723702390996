export const NODE_TYPE = 'paymentMethods' as const;

export const CARD_FUNDING = {
  CREDIT: 'credit',
  DEBIT: 'debit',
  PREPAID: 'prepaid',
  UNKNOWN: 'unknown',
} as const;

export const CARD_BRAND = {
  AMEX: 'amex',
  DINERS_CLUB: 'diners',
  DISCOVER: 'discover',
  EFTPOS_AU: 'eftpos_au',
  JCB: 'jcb',
  MASTERCARD: 'mastercard',
  UNIONPAY: 'unionpay',
  VISA: 'visa',
  
  UNKNOWN: 'unknown',
} as const;

export const CARD_BRAND_DISPLAY_NAME = {
  [CARD_BRAND.AMEX]: 'American Express',
  [CARD_BRAND.DINERS_CLUB]: 'Diners Club',
  [CARD_BRAND.DISCOVER]: 'Discover',
  [CARD_BRAND.EFTPOS_AU]: 'Eftpos',
  [CARD_BRAND.JCB]: 'JCB',
  [CARD_BRAND.MASTERCARD]: 'Mastercard',
  [CARD_BRAND.UNIONPAY]: 'UnionPay',
  [CARD_BRAND.VISA]: 'Visa',

  [CARD_BRAND.UNKNOWN]: 'Unknown',
} as const;

import React, { useState } from 'react';
import { Elements, CardElement, useElements, useStripe, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { Link, useLocation, useMatch } from 'react-router-dom';
import { StripeError } from '@stripe/stripe-js';

import useLoadStripe from '@clientCommon/library/hooks/useLoadStripe';

// Types
import { PaymentMethod } from '@/@common/models/nodes/paymentMethods/types';

// Components
import FormSection from '../../../../components/formSection';
import SectionsFooter from '../../../../components/sectionsFooter';
import FormFieldEmpty from '@/components/formField/empty';
import FormFieldString from '@/components/formField/string';
import Button from '@/components/button';
import InputString from '@/components/inputs/string';

// Styles
import styles from './styles.module.scss';
import Body2 from '@/components/text/body2';




type Props = {
  onPaymentMethodAdded: (event: React.FormEvent<HTMLFormElement>) => void;
  isLoading: boolean;
  isSubmitting?: boolean;
  nameOnCard: string | null;
  setNameOnCard: (name: string | null) => void;
  parentPath: string;
  stripeError: StripeError | null;
  errorMessage: string | null;
};

function AddPaymentMethodView({
  onPaymentMethodAdded,
  isLoading,
  isSubmitting = false,
  nameOnCard,
  setNameOnCard,
  parentPath,
  stripeError,
  errorMessage,
}: Props) {

  return (
    <div className={styles.AddPaymentMethod}>
      <form className={styles.addPaymentMethodForm} onSubmit={onPaymentMethodAdded}>
        <div className={styles.formContent}>
          <FormSection
            className={styles.formSection}
            label='Card details'
          >
            <div className={styles.cardInputsContainer}>
              <div className={styles.cardInputsPrimary}>
                <FormFieldString
                  className={styles.formField}
                  label='Name on card'
                  input={{
                    className: styles.inputString,
                    value: nameOnCard || '',
                    onChange: (e) => setNameOnCard(e.target.value),
                  }}
                />
                <FormFieldEmpty className={styles.formField} label='Card number'>
                  <CardNumberElement
                    className={`${styles.cardNumberElement}`}
                    options={{
                      style: {
                        base: {
                          fontFamily: 'Open Sans, sans-serif',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '24px',
                          letterSpacing: '0em',
                        },
                      },
                    }}
                  />
                </FormFieldEmpty>
              </div>
              <div className={styles.cardInputsSecondary}>
                <div className={styles.floatingInputContainer}>
                  <FormFieldEmpty className={styles.formField} label='Expiry'>
                    <CardExpiryElement
                      className={styles.cardExpiryElement}
                      options={{
                        style: {
                          base: {
                            fontFamily: 'Open Sans, sans-serif',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            letterSpacing: '0em',
                          },
                        },
                      }}
                    />
                  </FormFieldEmpty>
                </div>
                <div className={styles.floatingInputContainer}>
                  <FormFieldEmpty className={styles.formField} label='CVC'>
                    <CardCvcElement
                      className={styles.cardCVCElement}
                      options={{
                        style: {
                          base: {
                            fontFamily: 'Open Sans, sans-serif',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            letterSpacing: '0em',
                          },
                        },
                      }}
                    />
                  </FormFieldEmpty>
                </div>
              </div>
            </div>
            {
              errorMessage &&
              <div className={styles.cardErrorMessage}>
                <Body2 weight={'medium'} color='error'>{errorMessage}</Body2>
              </div>
            }
          </FormSection>
          <FormSection
            className={styles.formSection}
            label='Email addresses'
            description='Receipts will be sent to these email addresses'
          >
            <InputString
              className={styles.inputString}
            />
          </FormSection>  
          <SectionsFooter className={styles.formFooter}>
            <Link to={ parentPath }><Button invert={true}>Cancel</Button></Link>
            <Button type='submit' disabled={isLoading || isSubmitting}>
              Save Payment Method
            </Button>
          </SectionsFooter>    
        </div>
      </form>
    </div>
  );
}



export default AddPaymentMethodView;



import React from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';

// Children
import PaymentMethods from './children/paymentMethods';

// Components
import ContentContainer from '@/components/contentContainer';
import H1 from '@/components/text/h1';

// Views
import Navigation from './views/navigation';

// Styles
import styles from './styles.module.scss';



function UserProfileView() {
  return <div className={`${ styles.UserProfile }`}>
    <ContentContainer
      className={`${styles.contentContainer}`}
      anticipateScroll={ true }
    >
      <div className={styles.header}>
        <H1>Account overview</H1>
      </div>
      <div className={`${styles.body}`}>
        <div className={styles.navigationSection}>
          <Navigation />
        </div>
        <div className={styles.contentSection}>
          
          <Routes>
            <Route path='payment-methods/*' element={<PaymentMethods />} />
        
            <Route path='*' element={<Navigate to='payment-methods' />} />
          </Routes>
        </div>
      </div>
    </ContentContainer>
  </div>;
}

export default UserProfileView;

import React from 'react';

// Components
import Label from '@/components/text/label';

import styles from './styles.module.scss';


type Props = {
  label: string;
  showLabel?: boolean;
};

function FormFieldEmpty({
  className,
  label,
  showLabel = true,
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & Props) {

  return <div className={`${styles.FormFieldEmpty} ${className}`} {...props}>
    { showLabel && <Label className={styles.label}>{label}</Label> }
    { children }
  </div>
}

export default FormFieldEmpty;

import React from 'react';

// Views
import AppFrameView from './appFrameView';

function AppFrameContainer() {
  return <AppFrameView />;
}

export default AppFrameContainer;

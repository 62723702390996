import React from 'react';

import styles from './styles.module.scss';

function Label(
  {
    children,
    className,
    ...props
  }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLLabelElement>, HTMLLabelElement>
) {
  
  return <label
    className={ `${ className } ${ styles.text }` }
    { ...props }
  >{ children }</label>;
}

export default Label;

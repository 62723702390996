// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_text__GiVyb {\n  font-family: \"Open Sans\", sans-serif;\n  font-size: 32px;\n  font-weight: 500;\n  line-height: 44px;\n  letter-spacing: 0em;\n  margin: 0px;\n}", "",{"version":3,"sources":["webpack://./src/components/text/h3/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,oCAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,WAAA;AADF","sourcesContent":["@import \"@/styles/designTokens/layout.scss\";\n\n.text {\n  font-family: 'Open Sans', sans-serif;\n  font-size: 32px;\n  font-weight: 500;\n  line-height: 44px;\n  letter-spacing: 0em;\n  margin: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "styles_text__GiVyb"
};
export default ___CSS_LOADER_EXPORT___;

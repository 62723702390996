// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_text__MYdPm {\n  font-family: \"Open Sans\", sans-serif;\n  font-size: 14px;\n  line-height: 20px;\n  letter-spacing: 0em;\n  margin: 0px;\n}\n.styles_text__MYdPm.styles_regular__-ongr {\n  font-weight: 400;\n}\n.styles_text__MYdPm.styles_regular__-ongr.styles_color-secondary__q8QWf {\n  color: #475467;\n}\n.styles_text__MYdPm.styles_medium__PiibG {\n  font-weight: 600;\n}\n.styles_text__MYdPm.styles_medium__PiibG.styles_color-secondary__q8QWf {\n  color: #344054;\n}\n.styles_text__MYdPm.styles_boldish__04UJB {\n  font-weight: 700;\n}\n.styles_text__MYdPm.styles_boldish__04UJB.styles_color-secondary__q8QWf {\n  color: #344054;\n}\n.styles_text__MYdPm.styles_color-primary__5IOqM {\n  color: #000;\n}\n.styles_text__MYdPm.styles_color-error__eF-hD {\n  color: #ED4337;\n}", "",{"version":3,"sources":["webpack://./src/components/text/body2/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,oCAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,WAAA;AADF;AAGE;EACE,gBAAA;AADJ;AAEI;EACE,cAAA;AAAN;AAIE;EACE,gBAAA;AAFJ;AAGI;EACE,cAAA;AADN;AAKE;EACE,gBAAA;AAHJ;AAKI;EACE,cAAA;AAHN;AAOE;EACE,WAAA;AALJ;AAQE;EACE,cAAA;AANJ","sourcesContent":["@import \"@/styles/designTokens/layout.scss\";\n\n.text {\n  font-family: 'Open Sans', sans-serif;\n  font-size: 14px;\n  line-height: 20px;\n  letter-spacing: 0em;\n  margin: 0px;\n\n  &.regular {\n    font-weight: 400;\n    &.color-secondary {\n      color: #475467;\n    }\n  }\n\n  &.medium {\n    font-weight: 600;\n    &.color-secondary {\n      color: #344054;\n    }\n  }\n\n  &.boldish {\n    font-weight: 700;\n\n    &.color-secondary {\n      color: #344054;\n    }\n  }\n\n  &.color-primary {\n    color: #000;\n  }\n\n  &.color-error {\n    color: #ED4337;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "styles_text__MYdPm",
	"regular": "styles_regular__-ongr",
	"color-secondary": "styles_color-secondary__q8QWf",
	"medium": "styles_medium__PiibG",
	"boldish": "styles_boldish__04UJB",
	"color-primary": "styles_color-primary__5IOqM",
	"color-error": "styles_color-error__eF-hD"
};
export default ___CSS_LOADER_EXPORT___;

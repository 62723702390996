import React from 'react';

// Views
import NavigationView from './navigationView';

function NavigationContainer() {
  return <NavigationView />;
}

export default NavigationContainer;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_InputString__fHnO9 {\n  padding: 11px 12px;\n  border: solid 1px #D0D5DD;\n  border-radius: 8px;\n  box-sizing: border-box;\n  font-family: \"Open Sans\", sans-serif;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  letter-spacing: 0em;\n  outline: none;\n  width: 100%;\n}\n.styles_InputString__fHnO9:-webkit-autofill {\n  -webkit-text-fill-color: #000;\n  background-color: #FFF;\n  font-family: \"Open Sans\", sans-serif;\n  font-size: 16px !important;\n}\n.styles_InputString__fHnO9::-webkit-input-placeholder {\n  font-family: \"Open Sans\", sans-serif;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  letter-spacing: 0em;\n}\n.styles_InputString__fHnO9::placeholder {\n  font-family: \"Open Sans\", sans-serif;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  letter-spacing: 0em;\n}", "",{"version":3,"sources":["webpack://./src/components/inputs/string/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,yBAAA;EACA,kBAAA;EACA,sBAAA;EAEA,oCAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EAEA,aAAA;EAEA,WAAA;AAFF;AAIE;EACE,6BAAA;EACA,sBAAA;EACA,oCAAA;EACA,0BAAA;AAFJ;AAKE;EACE,oCAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AAHJ;AAFE;EACE,oCAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AAHJ","sourcesContent":[".InputString {\n  padding: 11px 12px;\n  border: solid 1px #D0D5DD;\n  border-radius: 8px;\n  box-sizing: border-box;\n\n  font-family: 'Open Sans', sans-serif;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  letter-spacing: 0em;\n\n  outline: none;\n\n  width: 100%;\n\n  &:-webkit-autofill {\n    -webkit-text-fill-color: #000;\n    background-color: #FFF;\n    font-family: 'Open Sans', sans-serif;\n    font-size: 16px !important;\n  }\n\n  &::placeholder {\n    font-family: 'Open Sans', sans-serif;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    letter-spacing: 0em;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InputString": "styles_InputString__fHnO9"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';

import styles from './styles.module.scss';

function ButtonText(
  {
    children,
    className,
    ...props
  }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>
) {
  
  return <span
    className={ `${ className } ${ styles.text }` }
    { ...props }
  >{ children }</span>;
}

export default ButtonText;

import { Auth } from 'aws-amplify';

export default async function getTokens() {
  const session = await Auth.currentSession();
  const accessToken = session.getAccessToken().getJwtToken();
  const idToken = session.getIdToken().getJwtToken();

  return {
    session,
    token: {
      access: accessToken,
      id: idToken,
    },
  };
}
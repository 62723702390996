import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

// Types
import { PaymentMethod } from '@common/models/nodes/paymentMethods/types';

// Views
import PaymentMethodsView from './paymentMethodsView';

// API
import useSyncWithStripe from '@clientCommon/app/models/payments/syncWithStripe/query';



function PaymentMethodsContainer() {
  const { data, refetch, isLoading, isFetching } = useSyncWithStripe();

  // Because stripe doesn't immediately have payment method available for sync, we keep
  // syncing until the returned payment methods contain the newly added one
  const [addedStripePaymentMethodId, setAddedStripePaymentMethodId] = React.useState<PaymentMethod['ref_stripe_paymentMethodId'] | null>(null);
  const [attemptCount, setAttemptCount] = React.useState<number>(0);
  const incrementAttemptCount = () => setAttemptCount((count) => count + 1);
  const timeout = React.useRef<number | null>(null);

  const paymentMethods = data?.paymentMethods ?? [];

  useEffect(() => {
    if (timeout.current) return;
    if (!addedStripePaymentMethodId) return;
    if (isLoading || isFetching) return;
    if (
      paymentMethods.some((pm) => pm.ref_stripe_paymentMethodId === addedStripePaymentMethodId) || 
      attemptCount > 10 // 10 attempts should be enough
    ) {
      setAttemptCount(0);
      setAddedStripePaymentMethodId(null);
      return;
    }

    timeout.current = setTimeout(() => {
      incrementAttemptCount();
      refetch();
      timeout.current = null;
    }, 500) as unknown as number;
  }, [addedStripePaymentMethodId, paymentMethods, isLoading, isFetching, refetch, attemptCount, incrementAttemptCount, setAddedStripePaymentMethodId, setAttemptCount]);


  console.log('Payment methods', paymentMethods);

  return <PaymentMethodsView
    paymentMethods={paymentMethods}
    isLoading={isLoading}
    onPaymentMethodAdded={setAddedStripePaymentMethodId}
  />;
}

export default PaymentMethodsContainer;

import { Auth } from 'aws-amplify';
import merge from 'lodash/merge';

// Utils
import getTokens from '@clientCommon/app/auth/getTokens';

type Config = {
  json?: boolean;
};

export default async function authenticatedFetch(url: string, options: RequestInit = {}, { json = true }: Config = {}) {
  const { token } = await getTokens();

  const response = await fetch(
    url,
    merge(options, {
      headers: merge({
        
        'Authorization': `Bearer ${token.access}`,
        'X-Id': token.id,
      }, json ? { 'Content-Type': 'application/json' } : {}),
    }),
  );

  return response;
}
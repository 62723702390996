import React, { useState, useRef, useEffect, useMemo, DetailedHTMLProps, HTMLAttributes } from 'react';

// Types
import { ContentGateProps } from './types.web.p';

// Hooks
import useFreshMount from './hooks/isFreshMount';

import image from './loader.gif';

import constructStyles from './styles/custom.web.p';

function useFadeout({
  active,
  fadeDuration,
  isFreshMount,
}: {
  active: boolean;
  fadeDuration: number;
  isFreshMount: boolean;
}) {
  const activeTimeout = useRef<number>(null);
  const [isFadingOut, setIsFadingOut] = useState(false);

  // If just mounted and active, initiate fadeout.
  useEffect(() => {
    fadeOut();
  }, [isFreshMount]);

  // If active state has changed to inactive, initiate fadeout.
  useEffect(() => {
    if (!active) fadeOut();
  }, [active]);

  function fadeOut() {
    activeTimeout.current && clearTimeout(activeTimeout.current);
    setIsFadingOut(true);
    // @ts-ignore it says this is readonly but its not.
    activeTimeout.current = setTimeout(() => {
      // @ts-ignore it says this is readonly but its not.
      activeTimeout.current = null;
      setIsFadingOut(false);
    }, fadeDuration);
  }

  return isFadingOut;
}

function ContentGate({
  active,
  fadeDuration = 500,
  style = {},
  children,
  className,
}: ContentGateProps & { className?: string }) {
  const isFreshMount = useFreshMount();
  const isFadingOut = useFadeout({ active, fadeDuration, isFreshMount });
  const styles = useMemo(() => constructStyles({ active }), [active]);

  // console.log('Is fresh mount', isFreshMount);

  // console.log('Is fading out', isFadingOut);

  const spinnerStyle = {
    transition: `opacity ${fadeDuration}ms`,
    opacity: ~~(isFreshMount || active),
  };

  return (
    <div className={`ContentGate ${className || ''}`} style={{ ...styles.contentGate, ...style }}>
      {(active || isFadingOut) && (
        <div style={{ ...spinnerStyle, ...styles.spinner }} className={`spinner`}>
          <img alt="Loading..." style={{ opacity: 'inherit' }} src={image} />
        </div>
      )}
      {!active && (children || false)}
    </div>
  );
}

export default ContentGate;

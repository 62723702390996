import React, { useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

// Components
import ContentContainer from '@/components/contentContainer';
import DealForgeLogo from '@clientCommon/app/components/logo';

import H1 from '@/components/text/h1';
import InputString from '@/components/inputs/string';
import FormFieldString from '@/components/formField/string';
import Body2 from '@/components/text/body2';
import Button from '@/components/button';

// Styles
import styles from './styles.module.scss';

// Images
import ImageGoogleIcon from '@clientCommon/library/assets/logos/google.svg';



type Props = {
  flow: 'login' | 'signup' | 'forgotPassword';
  // setFlow: (flow: 'login' | 'signup' | 'forgotPassword') => void;

  email: string;
  password: string;
  passwordConfirm: string;
  authError: string | null;

  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
  setPasswordConfirm: (passwordConfirm: string) => void;
  setAuthError: (authError: string | null) => void;

  onLogin: () => void;
  onSignup: () => void;
  onGoogleLogin: () => void;

  onForgotPassword: () => void;
};

function LoginView({
  flow,
  // setFlow,

  email,
  password,
  passwordConfirm,
  authError,

  setEmail,
  setPassword,
  setPasswordConfirm,
  setAuthError,

  onLogin,
  onSignup,
  onGoogleLogin,

  onForgotPassword,
}: Props) {

  const isSignUp = flow === 'signup';
  // const toggleIsSignup = () => setFlow(isSignUp ? 'login' : 'signup');
  const ref = {
    signUp: {
      password1: useRef<HTMLInputElement>(null),
      password2: useRef<HTMLInputElement>(null),
    },
  };

  const [isSignUpPassword1Focused, setIsSignUpPassword1Focused] = React.useState(false);
  const [isSignUpPassword2Focused, setIsSignUpPassword2Focused] = React.useState(false);


  useEffect(() => {
    setAuthError(null);
  }, [email, password, passwordConfirm]);

  const passwordMismatch = !isSignUpPassword1Focused && !isSignUpPassword2Focused && password?.length && password !== passwordConfirm;
  const passwordUnqualified = !isSignUpPassword1Focused && !isSignUpPassword2Focused && password?.length && (password.length < 8);
  const needsSecondPassword = !isSignUpPassword1Focused && !isSignUpPassword2Focused && isSignUp && password?.length && !passwordConfirm?.length;

  const error = authError ||
  (
    passwordUnqualified
    ? 'Password must be at least 8 characters.'
    : needsSecondPassword
      ? 'Please confirm your password.'
      : passwordMismatch
        ? 'Passwords do not match.'
        : null
  );


  setTimeout(() => {
    console.log(ref.signUp.password1.current?.value);
  }, 1000 * 10);


  return <ContentContainer className={styles.Login} center={false}>
    {/* <DealForgeLogo /> */}
    <div className={styles.flowContainer}>
    {
      flow === 'signup' ?
      <form onSubmit={(event) => {
        event.preventDefault();
        if (error) return;
        onSignup();
      }}>
        <H1>Sign up</H1>
        <Body2 className={styles.switchFlow}>
          Or <Link className={styles.underline} to='/login'>log in</Link>
        </Body2>
        <FormFieldString
          className={styles.formField}
          label='Email'
          input={{
            className: styles.inputString,
            type: 'text',
            value: email,
            onChange: (e) => setEmail(e.target.value),
            autoFocus: true,
          }}
        />
        <FormFieldString
          className={styles.formField}
          label='Password'
          input={{
            className: styles.inputString,
            type: 'password',
            value: password,
            onChange: (e) => setPassword(e.target.value),
            onFocus: () => setIsSignUpPassword1Focused(true),
            onBlur: () => setIsSignUpPassword1Focused(false),
          }}
          ref={ref.signUp.password1}
        />
        {
          error ?
          <Body2 className={styles.error}>{error}</Body2>
          : null
        }
        <FormFieldString
          className={styles.formField}
          label='Password Confirm'
          input={{
            className: styles.inputString,
            type: 'password',
            value: passwordConfirm,
            onChange: (e) => setPasswordConfirm(e.target.value),
            onFocus: () => setIsSignUpPassword2Focused(true),
            onBlur: () => setIsSignUpPassword2Focused(false),
          }}
          ref={ref.signUp.password2}
        />
 
        <div className={styles.actionArea}>
          <div className={styles.buttons}>
            <Button type="submit">Sign up</Button>
          </div>
          <div className={styles.oauthDivider}>
            <div className={styles.orContainer}><Body2 className={styles.or}>Or</Body2></div>
          </div>
          <div className={styles.oauthOptions}>
            <Button onClick={onGoogleLogin} fullWidth={true}>
              <div className={styles.oauthButtonContent}>
                <div className={styles.iconContainer}>
                  <img src={ImageGoogleIcon} alt="Google Icon" />
                </div>
                <Body2>Continue with Google</Body2>
              </div>
            </Button>
          </div>
        </div>
      </form>
      : flow === 'login'
      ?
      <form 
        className={styles.loginForm}
        onSubmit={(event) => {
          event.preventDefault();
          onLogin();
        }}
        >
        <H1>Log in</H1>
        <Body2 className={styles.switchFlow}>
          Or <Link className={styles.underline} to='/signup'>create an account</Link>
        </Body2>
        <FormFieldString
          className={styles.formField}
          label='Email'
          input={{
            className: styles.inputString,
            type: 'text',
            value: email,
            onChange: (e) => setEmail(e.target.value),
            autoFocus: true,
          }}
        />
        <FormFieldString
          className={styles.formField}
          label='Password'
          input={{
            className: styles.inputString,
            type: 'password',
            value: password,
            onChange: (e) => setPassword(e.target.value),
            autoFocus: true,
          }}
        />
        <div className={styles.actionArea}>
          <div className={styles.buttons}>
            <Button type="submit">Log In</Button>
          </div>
          <div className={styles.alternateActions}>
            <Link to='/forgot-password'><Body2 className={styles.forgotPassword}>Forgot Password?</Body2></Link>
            <Link to=''>
              <Body2>Contact Us</Body2>
            </Link>
          </div>
          <div className={styles.oauthDivider}>
            <div className={styles.orContainer}><Body2 className={styles.or}>Or</Body2></div>
          </div>
          <div className={styles.oauthOptions}>
            <Button onClick={onGoogleLogin} fullWidth={true}>
              <div className={styles.oauthButtonContent}>
                <div className={styles.iconContainer}>
                  <img src={ImageGoogleIcon} alt="Google Icon" />
                </div>
                <Body2>Continue with Google</Body2>
              </div>
            </Button>
          </div>
        </div>
        
      </form>
      : 
      <form onSubmit={(event) => {
        event.preventDefault();
        onForgotPassword();
      }}>
        <InputString
          className={styles.inputString}
          type='text'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button type="submit">Email Me Reset Code</button>
      </form>
    }
    </div>
  </ContentContainer>;
}

export default LoginView;

import React, { useCallback, useEffect } from 'react';
import { BrowserRouter, useNavigate } from 'react-router-dom';

// Hooks
import useAuth from '@/@clientCommon/library/hooks/useAuth';

// Views
import AppView from './appView';

// API
import findOrCreateByAccessToken from '@clientCommon/app/models/auth/findOrCreateByAccessToken';






function AppContainer() {
  const navigate = useNavigate();

  const onLogin = useCallback(() => {
    navigate('/');
  }, [useNavigate]);

  const { isAuthenticated, token } = useAuth({
    onLogin,
  });

  useEffect(() => {
    if (token.access) findOrCreateByAccessToken();
  }, [token.access]);

  return <AppView />;
}

function AppContainerWrapper() {
  return <BrowserRouter>
    <AppContainer />
  </BrowserRouter>;
}

export default AppContainerWrapper;

import React from 'react';


// Styles
import './emailVerifyStyles.scss';

type Props = {

  email: string;
  verificationCode: string;


  setEmail: (email: string) => void;
  setVerificationCode: (verificationCode: string) => void;

  confirmEmailVerification: () => void;
};

function EmailVerifyView({
  email,
  verificationCode,

  setEmail,
  setVerificationCode,

  confirmEmailVerification,
}: Props) {
  return <div>
    <form onSubmit={(event) => {
      event.preventDefault();
      confirmEmailVerification();
    }}>
      <input
        type='text'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type='text'
        value={verificationCode}
        onChange={(e) => setVerificationCode(e.target.value)}
      />
     
      <button type="submit">Verify</button>
    </form>
  </div>;
}

export default EmailVerifyView;

import React from 'react';

import styles from './styles.module.scss';

function H3(
  {
    children,
    className,
    ...props
  }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>
) {
  
  return <h3
    className={ `${ className } ${ styles.text }` }
    { ...props }
  >{ children }</h3>;
}

export default H3;

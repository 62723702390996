import React from 'react';

// Components
import ButtonText from '../text/button';

// Styles
import styles from './styles.module.scss';


type Props = {
  text?: string;
  fullWidth?: boolean;
  invert?: boolean;
};

function Button(
  {
    children,
    className,
    fullWidth,
    invert = false,
    text,
    ...props
  }: React.ButtonHTMLAttributes<HTMLButtonElement> & Props
) {
  return <button
    className={ `${ className } ${ styles.button } ${ fullWidth && styles.fullWidth } ${ invert && styles.inverted }` }
    { ...props }
  >
  {
    <ButtonText>{ children || text }</ButtonText>
  }
  </button>;
}

export default Button;

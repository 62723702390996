import { SetupIntent } from '@stripe/stripe-js';

import authenticatedFetch from '@clientCommon/app/auth/authenticatedFetch';



type Params = {
  idempotencyKey: string;
};

/**
 * Generates a payment method setup intent and returns the client secret.
 */
export default async function generatePaymentMethodSetup(
  { idempotencyKey }: Params,
): Promise<SetupIntent['client_secret']> {
  const response = await authenticatedFetch(
    process.env.REACT_APP_API_ENDPOINT_PAYMENTS_GENERATE_PAYMENT_METHOD_SETUP as string,
    {
      method: 'POST',
      body: JSON.stringify({
        idempotencyKey,
      }),
    },
  );

  if (!response.ok) {
    console.log('Failed to generate payment method setup', response);
    throw new Error('Failed to generate payment method setup');
  }
  return response.text();
}

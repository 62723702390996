import React from 'react';
import { BrowserRouter, Navigate, Route, Router, Routes, useNavigate } from 'react-router-dom';

// Routes
import AppFrame from './children/appFrame';
import AuthCallback from './children/authCallback/authCallback';
import EmailVerify from './children/emailVerify';
import Login from './children/login';


// Hooks
import useAuth from '@clientCommon/library/hooks/useAuth';

import ContentGate from '@clientCommon/library/components/contentGate/index.web.p';

// function AppFrame() {
//   return <div>I AM APP FRAME</div>;
// }


// Styles
import './appStyles.scss';

// function AppViewWrapper() {
//   return (
//     <BrowserRouter>
//       <AppView />
//     </BrowserRouter>
//   );
// }

function AppView() {
  const navigate = useNavigate();

  // Navigate must be used in a Router, hence here instead of containers
  const { hasDoneInitialCheck, isAuthenticated } = useAuth({
    onAuthError: (err) => {
      console.error('AUTH ERROR', err);
      if (window.location.pathname.match(/signup/)) return;
      navigate('/login');
    },
  });

  console.log('HAS DONE INITIAL CHECK', hasDoneInitialCheck, isAuthenticated);

  return (
    <ContentGate className='c-RootView' active={!hasDoneInitialCheck} fadeDuration={500}>
      <Routes>
        <Route
          path='/auth-callback'
          element={
            <AuthCallback />
          }
        />
        <Route
          path='/auth-email-verify'
          element={
            <EmailVerify />
          }
        />
        <Route
          path='/login'
          element={
            <Login
              // onLogin={() => {
              //   navigate('/');
              // }}
            />
          }
        />
        <Route
          path='/signup'
          element={
            <Login
              // onLogin={() => {
              //   navigate('/');
              // }}
            />
          }
        />

        {/* <Route path='/reset-password' element={<ResetPasswordContainer/>} />
      <Route path='/change-password' element={<ChangePasswordContainer/>} /> */}

        <Route path='*' element={isAuthenticated ? <AppFrame /> : <Navigate to='/login' />} />
      </Routes>
    </ContentGate>
  );
  // return <div>
  //   <Login
  //     onLogin={(...args) => {
  //       console.log('LOGIN RESPONSE', ...args);
  //     }}
  //   />
  // </div>
}

export default AppView;
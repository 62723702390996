import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Elements, CardElement, useElements, useStripe, CardNumberElement } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

// Types
import { PaymentMethod } from '@common/models/nodes/paymentMethods/types';

// Hooks
import useLoadStripe from '@clientCommon/library/hooks/useLoadStripe';

// Views
import AddPaymentMethodView from './addPaymentMethodView';

// API
import generatePaymentMethodSetup from '@clientCommon/app/models/payments/generatePaymentMethodSetup';


const useClientSecret = (idempotencyKey: string | null) => {
  return useQuery(['payments', 'clientSecret', idempotencyKey], () => generatePaymentMethodSetup({ idempotencyKey: idempotencyKey! }), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: idempotencyKey !== null,
  });
};


type Props = {
  onPaymentMethodAdded: (stripe_paymentMethodId: PaymentMethod['ref_stripe_paymentMethodId']) => void;
};

function AddPaymentMethodContainer({
  onPaymentMethodAdded,
}: Props) {
  const stripe = useStripe();
  const elements = useElements();

  const [idempotencyKey, setIdempotencyKey] = useState<string | null>(null);
  const [stripeError, setStripeError] = useState<StripeError | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  

  const [nameOnCard, setNameOnCard] = useState<string | null>(null);

  function generateIdempotencyKey() {
    setIdempotencyKey(Date.now() + String(Math.random()).replace('0.', ''));
  }

  useEffect(() => {
    generateIdempotencyKey();
  }, []);


  console.log('Idempotency key', idempotencyKey);

  const { data: clientSecret, refetch: refetchClientSecret, isLoading } = useClientSecret(idempotencyKey);

  console.log('CLIENT SECRET', clientSecret);

  const location = useLocation();
  const navigate = useNavigate();
  const parentPath = location.pathname.split('/').slice(0, -1).join('/');


  async function savePaymentMethod(event: React.FormEvent<HTMLFormElement>) {
    console.log('Saving payment method');
    event.preventDefault();

    if (!stripe || !elements || !clientSecret) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (!cardElement) return;
    

    setIsSubmitting(true);


    console.log('Sumitting to stripe');
    try {
      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: nameOnCard || '',
            // email: checkAuth()?.email || '',
            // address: {
            //   city: '',
            //   state: '',
            //   line1: '',
            //   line2: '',
            // },
          },
          metadata: {
            // userId
          },
        },
      });

      // We shouldn't have to regenerate idempotency key/client secret on error unless the result says that the setup
      // intent was canceled.
      if (result.error) {
        setStripeError(result.error);
        console.log('Stripe error without throwing', result.error);
        if (['card_error', 'validation_error'].includes(result.error.type)) {
          console.log('Error with payment method', result.error);
          setErrorMessage(result.error.message || 'Experienced an issue with your payment method.');
        }
        else {
          console.log('Stripe error', result.error);
          setErrorMessage('We encountered an unexpected error. Please try again.');
        }

        if (result.error.setup_intent?.status === 'canceled') {
          generateIdempotencyKey();
          // refetchClientSecret(); // TODO ensure this is refetched when idempotency key changes
        }
      } else {
        console.log('Stripe result', result);
        // @ts-ignore
        onPaymentMethodAdded(result.setupIntent.payment_method);
        navigate(parentPath);
      }
    } catch (err) {
      console.log('Unexpected Stripe err', err);
      setErrorMessage('We encountered an unexpected error. Please try again.');
    }

    setIsSubmitting(false);
  };

  return <AddPaymentMethodView
    onPaymentMethodAdded={savePaymentMethod}
    isLoading={isLoading || !clientSecret}
    isSubmitting={isSubmitting}
    nameOnCard={nameOnCard}
    setNameOnCard={setNameOnCard}
    parentPath={parentPath}
    stripeError={stripeError}
    errorMessage={errorMessage}
  />;
}

function AddPaymentMethodContainerWrapper({ onPaymentMethodAdded }: Props) {
  const stripePromise = useLoadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

  return (
    <Elements stripe={stripePromise}>
      <AddPaymentMethodContainer onPaymentMethodAdded={onPaymentMethodAdded} />
    </Elements>
  );
};

export default AddPaymentMethodContainerWrapper;

import { QueryOptions, UseQueryOptions, useQuery } from '@tanstack/react-query';
import merge from 'lodash/merge';

// Types
import { PaymentMethod } from '@common/models/nodes/paymentMethods/types';
import { StripeCustomer } from '@common/models/nodes/stripeCustomers/types';

// Utils
import authenticatedFetch from '@clientCommon/app/auth/authenticatedFetch';





/**
 * Syncs current user/customer with stripe and loads their payment methods.
 */
export default async function syncWithStripe(
): Promise<{
  stripeCustomer: StripeCustomer;
  paymentMethods: PaymentMethod[];
}> {
  const response = await authenticatedFetch(
    process.env.REACT_APP_API_ENDPOINT_PAYMENTS_SYNC_WITH_STRIPE as string,
  );

  if (!response.ok) {
    console.log('Failed to sync with stripe', response);
    throw new Error('Failed to sync with stripe.');
  }
  return response.json();
}

import React, { forwardRef } from 'react';

// Components
import InputString from '@/components/inputs/string';
import Label from '@/components/text/label';

import styles from './styles.module.scss';

type Props = {
  label: string;
  input: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
};

const FormFieldString = forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & Props
>((
  {className, label, input, ...props},
  ref,
) => {
  const inputProps = {...input, ref}; // Add the ref to the input props
  return (
    <div className={`${styles.FormFieldString} ${className}`} {...props}>
      <Label className={styles.label}>{label}</Label>
      <InputString {...inputProps} />
    </div>
  )
});

export default FormFieldString;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_button__ARIEW {\n  padding: 15px 24px;\n  border-radius: 50px;\n  background-color: #000;\n  color: #fff;\n  outline: none;\n  align-self: flex-start;\n  border: none;\n  cursor: pointer;\n}\n.styles_button__ARIEW.styles_inverted__HX8LE {\n  background-color: #fff;\n  color: #000;\n}\n.styles_button__ARIEW:disabled {\n  opacity: 0.5;\n  cursor: not-allowed;\n}\n.styles_button__ARIEW.styles_fullWidth__CNHAi {\n  align-self: stretch;\n}", "",{"version":3,"sources":["webpack://./src/components/button/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,mBAAA;EACA,sBAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,eAAA;AADF;AAGE;EACE,sBAAA;EACA,WAAA;AADJ;AAIE;EACE,YAAA;EACA,mBAAA;AAFJ;AAKE;EACE,mBAAA;AAHJ","sourcesContent":["@import \"@/styles/designTokens/layout.scss\";\n\n.button {\n  padding: 15px 24px;\n  border-radius: 50px;\n  background-color: #000;\n  color: #fff;\n  outline: none;\n  align-self: flex-start;\n  border: none;\n  cursor: pointer;\n\n  &.inverted {\n    background-color: #fff;\n    color: #000;\n  }\n\n  &:disabled {\n    opacity: 0.5;\n    cursor: not-allowed;\n  }\n\n  &.fullWidth {\n    align-self: stretch;\n  }\n}\n\n@media (min-width: $tablet-min)  {\n  .button {\n \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "styles_button__ARIEW",
	"inverted": "styles_inverted__HX8LE",
	"fullWidth": "styles_fullWidth__CNHAi"
};
export default ___CSS_LOADER_EXPORT___;

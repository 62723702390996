import React from 'react';

import styles from './styles.module.scss';

type Props = {
  anticipateScroll?: boolean;
  center?: boolean;
};

function ContentContainer(
  {
    children,
    className,
    anticipateScroll,
    center = true,
    ...props
  }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> & Props
) {
  return <div
    className={ `${ className } ${ styles.contentContainer } ${ anticipateScroll && styles.anticipateScroll } ${ center && styles.center }` }
    { ...props }
  >{ children }</div>;
}

export default ContentContainer;

import React from 'react';

import styles from './styles.module.scss';

function H1(
  {
    children,
    className,
    ...props
  }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>
) {
  
  return <h1
    className={ `${ className } ${ styles.text }` }
    { ...props }
  >{ children }</h1>;
}

export default H1;

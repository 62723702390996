import authenticatedFetch from '@clientCommon/app/auth/authenticatedFetch';

export default async function findOrCreateByAccessToken() {
  const response = await authenticatedFetch(
    process.env.REACT_APP_API_ENDPOINT_AUTH_FIND_OR_CREATE_BY_ACCESS_TOKEN as string,
  );
  if (!response.ok) {
    throw new Error('Failed to fetch user');
  }
  return response.json();
}

import React, { useCallback } from 'react';
import { Amplify, Auth } from 'aws-amplify';

// Views
import EmailVerifyView from './emailVerifyView';


function EmailVerifyContainer() {
  const [email, setEmail] = React.useState('');
  const [verificationCode, setVerificationCode] = React.useState('');

  // TODO pull email out of url


  const confirmEmailVerification = useCallback(async () => {
    try {
      await Auth.confirmSignUp(email, verificationCode);
      console.log('Email verified');
    } catch (error) {
      console.error('Error verifying email:', error);
    }
  }, [email, verificationCode]);


  return <EmailVerifyView
    email={email}
    setEmail={setEmail}
    verificationCode={verificationCode}
    setVerificationCode={setVerificationCode}
    confirmEmailVerification={confirmEmailVerification}
  />;
}

export default EmailVerifyContainer;

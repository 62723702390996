import React from 'react';
import { Link, Navigate, Route, Routes } from 'react-router-dom';

// Types
import { PaymentMethod } from '@common/models/nodes/paymentMethods/types';

// Components
import PaymentMethods from '@/@clientCommon/app/components/paymentMethods';
import H2 from '@/components/text/h2';
import Body2 from '@/components/text/body2';

// Children
import AddPaymentMethod from './children/add';

// Styles
import styles from './styles.module.scss';
import SectionHeader from '../../components/sectionsHeader';

// Icons
import IconPlus from '@clientCommon/library/assets/icons/plus.svg';


type Props = {
  isLoading: boolean;
  paymentMethods: PaymentMethod[];
  onPaymentMethodAdded: (stripe_paymentMethodId: PaymentMethod['ref_stripe_paymentMethodId']) => void;
};


function PaymentMethodView({
  isLoading,
  paymentMethods,
  onPaymentMethodAdded,
}: Props) {
  return <div
    className={`${ styles.PaymentMethod }`}
  >
    <SectionHeader
      title={'Payment Methods'}
      subtitle={'Update your billing details'}
    ></SectionHeader>
    <Routes>
      <Route path='/' element={
        <div className={styles.paymentMethodsContainer}>
          <div className={styles.subheaderContainer}>
            <div className={styles.subheader}>
              <Body2 weight={'medium'} color={'secondary'}>Card Details</Body2>
              <Body2 color={'secondary'}>Select default payment method.</Body2>
            </div>
          </div>
          <div className={styles.paymentMethodsListContainer}>
            <PaymentMethods paymentMethods={paymentMethods} />
            <Link to='add'>
              <div className={`${styles.addPaymentMethod } ${!paymentMethods.length && styles.noPaymentMethods}`}>
                <img src={IconPlus} alt='add payment method' />
                <Body2 weight={'boldish'} color={'secondary'}>Add Payment Method</Body2>
              </div>
            </Link>
          </div>
        </div>
      } />
      <Route path='add' element={<AddPaymentMethod
        onPaymentMethodAdded={onPaymentMethodAdded}
      />} />
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  </div>;
}

export default PaymentMethodView;

import React from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';

// Types
import { AuthUser } from '@clientCommon/library/hooks/useAuth';

// Components
import ContentContainer from '@/components/contentContainer';

// Styles
import styles from './styles.module.scss';
import H3 from '@/components/text/h3';

type Props = {
  cognitoUser: AuthUser | null;
  logout: () => void;
};

function HeaderView({
  cognitoUser,
  logout,
}: Props) {
  return <div className={`c-Header ${styles.Header}`}>
    <ContentContainer className={styles.content}>
      <div className={styles.left}>
        <H3 className={ styles.logo }>DealForge</H3>
      </div>
      <div className={styles.mid}></div>
      <div
        className={styles.right}
        onClick={logout}
      >
        { cognitoUser?.attributes.email }
      </div>
    </ContentContainer>
  </div>;
}

export default HeaderView;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_UserProfileNavigation__jAQf0 {\n  display: flex;\n  flex-direction: column;\n}\n.styles_UserProfileNavigation__jAQf0 > .styles_navigationItem__nI66n {\n  padding: 8px 12px;\n  border-radius: 6x;\n  margin-bottom: 8px;\n  color: #CCCCCC;\n}", "",{"version":3,"sources":["webpack://./src/app/children/appFrame/children/userProfile/views/navigation/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,iBAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;AACJ","sourcesContent":[".UserProfileNavigation {\n  display: flex;\n  flex-direction: column;\n\n  >.navigationItem {\n    padding: 8px 12px;\n    border-radius: 6x;\n    margin-bottom: 8px;\n    color: #CCCCCC;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UserProfileNavigation": "styles_UserProfileNavigation__jAQf0",
	"navigationItem": "styles_navigationItem__nI66n"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';

// Styles
import styles from './styles.module.scss';
import Label from '@/components/text/label';

function  NavigationView() {
  return <ul className={styles.UserProfileNavigation}>
    <li className={styles.navigationItem}>
      <Label><s>My Info</s></Label>
    </li>
    <li className={styles.navigationItem}>
      <Label><s>Payment Methods</s></Label>
    </li>
    <li className={styles.navigationItem}>
      <Label><s>Notifications</s></Label>
    </li>
    <li className={styles.navigationItem}>
      <Label><s>Contact Us</s></Label>
    </li>
  </ul>;
}

export default NavigationView;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_FormFieldString__c\\+OqL {\n  display: flex;\n  flex-direction: column;\n}\n.styles_FormFieldString__c\\+OqL .styles_label__BDLxy {\n  margin-bottom: 4px;\n}", "",{"version":3,"sources":["webpack://./src/components/formField/string/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,kBAAA;AACJ","sourcesContent":[".FormFieldString {\n  display: flex;\n  flex-direction: column;\n\n  .label {\n    margin-bottom: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormFieldString": "styles_FormFieldString__c+OqL",
	"label": "styles_label__BDLxy"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';

// Components
import H2 from '@/components/text/h2';
import Body2 from '@/components/text/body2';

// Styles
import styles from './styles.module.scss';
import Label from '@/components/text/label';

type Props = {
  label: string;
  description?: string;
};

function FormSection(
  {
    className,
    label,
    description,
    children,
    ...props
  }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & Props
) {
  return <div className={`${styles.FormSection} ${className}`} { ...props }>
    <div className={styles.labelSection}>
      <Label className={styles.label} color={'secondary'}>{label}</Label>
      <Body2 className={styles.description} color='secondary'>{description}</Body2>
    </div>
    <div className={styles.contentSection}>
      { children }
    </div>
  </div>;
}

export default FormSection;

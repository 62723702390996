import React from 'react';

// Types
import { PaymentMethod } from '@common/models/nodes/paymentMethods/types';

// Views
import PaymentMethodsView from './paymentMethodsVew';


type Props = {
  paymentMethods: PaymentMethod[];
};

function PaymentMethodsContainer(props: Props) {
  return <PaymentMethodsView
    {...props}
  />;
}

export default PaymentMethodsContainer;

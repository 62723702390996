import React from 'react';

// Components
import H2 from '@/components/text/h2';
import Body2 from '@/components/text/body2';

// Styles
import styles from './styles.module.scss';

type Props = {
  title: string;
  subtitle: string;
};

function SectionHeader({
  title,
  subtitle,
}: Props) {
  return <div className={styles.SectionHeader}>
    <H2>{ title }</H2>
    <Body2>{ subtitle }</Body2>
  </div>;
}

export default SectionHeader;

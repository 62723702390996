import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// API
import findOrCreateByAccessToken from '@clientCommon/app/models/auth/findOrCreateByAccessToken';

// This view will never show since auth is handled at the root level
function AuthCallback() {
  const navigate = useNavigate();

  useEffect(() => {
    async function handleAuthCallback() {
      try {
        const result = await Auth.currentAuthenticatedUser();
        // findOrCreateByAccessToken();
        console.log('AUTH CALLBACK RESULT', result);
        navigate('/');
      } catch (error) {
        console.error(error);
        navigate('/login');
      }
    }

    handleAuthCallback();
  }, [navigate]);

  return <div>Authenticating...</div>;
};

export default AuthCallback;

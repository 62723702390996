import React from 'react';

// Types
import { PaymentMethod } from '@common/models/nodes/paymentMethods/types';

// Components
import Body2 from '@/components/text/body2';

// Constants
import { CARD_BRAND, CARD_BRAND_DISPLAY_NAME } from '@common/models/nodes/paymentMethods/constants';

// Styles
import styles from './styles.module.scss';

import Amex from '@clientCommon/library/assets/icons/paymentMethods/AMEX.svg';
import DinersClub from '@clientCommon/library/assets/icons/paymentMethods/DinersClub.svg';
import Discover from '@clientCommon/library/assets/icons/paymentMethods/Discover.svg';
import JCB from '@clientCommon/library/assets/icons/paymentMethods/JCB.svg';
import Mastercard from '@clientCommon/library/assets/icons/paymentMethods/Mastercard.svg';
import Visa from '@clientCommon/library/assets/icons/paymentMethods/Visa.svg';
import UnionPay from '@clientCommon/library/assets/icons/paymentMethods/UnionPay.svg';
import Unknown from '@clientCommon/library/assets/icons/paymentMethods/credit-card-01.svg';


const IMAGES = {
  [CARD_BRAND.AMEX]: Amex,
  [CARD_BRAND.DINERS_CLUB]: DinersClub,
  [CARD_BRAND.DISCOVER]: Discover,
  [CARD_BRAND.JCB]: JCB,
  [CARD_BRAND.MASTERCARD]: Mastercard,
  [CARD_BRAND.VISA]: Visa,
  [CARD_BRAND.UNIONPAY]: UnionPay,
  [CARD_BRAND.UNKNOWN]: Unknown,
};

type Props = {
  paymentMethods: PaymentMethod[];
};

function PaymentMethodsView ({ paymentMethods }: Props) {
  
  return (
    <ul className={styles.PaymentMethods}>
      {paymentMethods.map((method) => {
        const card = method.computed_card;
        if (!card) return null;
        const brand = CARD_BRAND_DISPLAY_NAME[card.brand];
        const expiryMatches = (method.computed_expiresAt || '').match(/^([0-9]{4})-([0-9]{2})-([0-9]{2})/);
        const expiry = expiryMatches ? `${expiryMatches[2]}/${expiryMatches[3]}/${expiryMatches[1]}` : null;

        return <li key={method.id} className={styles.paymentMethod}>
          <div className={styles.logoContainer}>
            <img
              src={IMAGES[card.brand as keyof typeof IMAGES] as string}
              // alt={`${method.brand} logo`}
              className={styles.brandLogo}
            />
          </div>
          <div className={styles.cardDetails}>
            <Body2 className={styles.lastFour} weight={'medium'} color={'secondary'}>{brand} ending in {card.last4 || 'XXXX'}</Body2>
            {
              expiry && <Body2 className={styles.expiry} weight={'regular'} color={'secondary'}>{`Expiry ${expiry}`}</Body2>
            }
          </div>
        </li>;
      })}
    </ul>
  );
};

export default PaymentMethodsView;

import { merge as _merge } from 'lodash';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

import constructCommonStyles from './common';

import { ContentGateStyleParams } from './types';

const constructWebStyles = (params: ContentGateStyleParams): Record<string, DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>['style']> => {
  const webStyles = {};
  const commonStyles = constructCommonStyles(params);

  return _merge(commonStyles, webStyles) as Record<string, DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>['style']>;
};

export default constructWebStyles;

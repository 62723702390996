import React from 'react';

// Components
import H2 from '@/components/text/h2';
import Body2 from '@/components/text/body2';

// Styles
import styles from './styles.module.scss';

type Props = {

};

function SectionFooter({
  className,
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & Props) {
  return <div className={`${ styles.SectionFooter } ${ className }`}>
    { children }
  </div>;
}

export default SectionFooter;

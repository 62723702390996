import React from 'react';

import styles from './styles.module.scss';

type Props = {
  weight?: 'regular' | 'medium' | 'boldish';
  color?: 'primary' | 'secondary' | 'error';
};

function Body2(
  {
    children,
    className,
    weight = 'regular',
    color='primary',
    ...props
  }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement> & Props
) {
  
  return <p
    className={ `${ className } ${ styles.text } ${ styles[weight]} ${ styles[`color-${color}`]}` }
    { ...props }
  >{ children }</p>;
}

export default Body2;

import { QueryOptions, useQuery } from '@tanstack/react-query';

import syncWithStripe from '.';


export default function useSyncWithSripe<
  O extends Omit<QueryOptions<Awaited<ReturnType<typeof syncWithStripe>>>, 'queryKey' | 'queryFn'>
>(options?: O) {
  const defaults = {
    retry: (count: number) => count < 4,
    refetchOnWindowFocus: false,
    enabled: true,
    keepPreviousData: false,
  };

  return useQuery({
    queryKey: ['payments', 'syncing'],
    queryFn: () => syncWithStripe(),
    ...defaults,
    ...options
  });
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_AppFrame__O2y7- {\n  display: flex;\n  flex-direction: column;\n  flex: 1 1 100%;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/app/children/appFrame/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,cAAA;EACA,mBAAA;AACF","sourcesContent":[".AppFrame {\n  display: flex;\n  flex-direction: column;\n  flex: 1 1 100%;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AppFrame": "styles_AppFrame__O2y7-"
};
export default ___CSS_LOADER_EXPORT___;

// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.scss';
// import App from './app/appContainer';
// // import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './app/appContainer';

import { Amplify } from 'aws-amplify';

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

import './styles/classes/grid.scss';

console.log('SUP TEST', process.env.REACT_APP_USER_POOL_ID);


Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: [
        // These properties will be added to the token and can be viewed in the decoded token
        'email',
        'openid',
        'profile',
        // This property allows me to use the access token on the server for fetching user from cognito
        // as well as performing actions in cognito if necessary
        'aws.cognito.signin.user.admin'
      ],
      redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
      responseType: 'code',
    },
  },
});

console.log('COGNITO CONFIG', {
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
});


// Create a client
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);

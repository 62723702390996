import React from 'react';

import styles from './styles.module.scss';

function H2(
  {
    children,
    className,
    ...props
  }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>
) {
  
  return <h2
    className={ `${ className } ${ styles.text }` }
    { ...props }
  >{ children }</h2>;
}

export default H2;

import React from 'react';

// Hooks
import useAuth from '@clientCommon/library/hooks/useAuth';

// Views
import HeaderView from './headerView';


function HeaderContainer() {
  const { cognitoUser, logout } = useAuth({});


  return <HeaderView
    cognitoUser={cognitoUser}
    logout={logout}
  />;
}

export default HeaderContainer;
